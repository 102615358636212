import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Canvas from './Canvas';
import Versions from './Versions/Versions'; // Assuming you have a Versions component

function App() {
    return (
        <Router>
            <Routes>
                {/* Route for the Versions component */}
                <Route path="/versions" element={<Versions />} />

                {/* Default route for all other paths */}
                <Route path="*" element={<Canvas />} />
            </Routes>
        </Router>
    );
}

export default App;
