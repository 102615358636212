import React, { useRef, useEffect, useState } from 'react';
import { drawSun, drawSunlightEffect, calculateSunPosition, getDaylightColor } from './utils/sunUtils';
import { calculateTreePositions, drawTrees } from './utils/treeUtils';
import { generateMountainData, drawMountains } from './utils/mountainUtils';
import { formatDateTime } from './utils/dateUtils';
import { drawStars, generateStarData } from './utils/starsUtils';

const CURRENT_VERSION = 'v2.2';

const Canvas = () => {
    const canvasRef = useRef(null);
    const [currentDateTime, setCurrentDateTime] = useState(new Date()); // '2022-01-01T08:30:00'
    const [mountainData, setMountainData] = useState([]);
    const [trees, setTrees] = useState([]);
    const [starData, setStarData] = useState([]);
    const [multiplier, setMultiplier] = useState(1);

    const drawCanvas = (ctx, canvas) => {
        ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas

        const sunPosition = calculateSunPosition(currentDateTime, canvas);
        const { sunX, sunY } = sunPosition;

        const bgColor = getDaylightColor(sunX, sunY, canvas);
        ctx.fillStyle = bgColor;
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        drawStars(ctx, canvas, starData);

        ctx.save();
        ctx.translate(0, canvas.height);
        ctx.scale(1, -1);

        drawMountains(ctx, canvas, mountainData, sunX, sunY);

        drawTrees(ctx, trees);

        const edgeBuffer = 50;
        if (sunX >= -1 * edgeBuffer && sunX <= (canvas.width + edgeBuffer) && sunY >= 0 && sunY <= canvas.height) {
            drawSunlightEffect(ctx, sunX, sunY, canvas);
        }

        drawSun(ctx, canvas, currentDateTime);

        ctx.restore();
    };

    const resizeCanvas = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        const newMountainData = generateMountainData(canvas);
        setMountainData(newMountainData);

        const newTreePositions = calculateTreePositions(canvas);
        setTrees(newTreePositions);

        const newStarData = generateStarData(canvas, 500);
        setStarData(newStarData);
    };

    // Timer to update the current date-time every second
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDateTime(prevTime => new Date(prevTime.getTime() + 1000 * multiplier));
        }, 1000 / multiplier);

        return () => clearInterval(interval);
    }, [multiplier]);

    // Redraw the canvas when the date-time, mountains, trees, or stars change
    useEffect(() => {
        if (mountainData.length > 0 && trees.length > 0 && starData.length > 0) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            drawCanvas(ctx, canvas);
        }
    }, [currentDateTime, mountainData, trees, starData]);

    // Handle canvas resizing
    useEffect(() => {
        resizeCanvas();

        window.addEventListener('resize', resizeCanvas);

        return () => {
            window.removeEventListener('resize', resizeCanvas);
        };
    }, []);

    // Handle fast forward
    const handleFastForward = () => {
        setMultiplier(prevMultiplier => (prevMultiplier < 61 ? prevMultiplier * 4 : 1));
    };

    // Handle reset
    const handleReset = () => {
        setMultiplier(1);
        setCurrentDateTime(new Date());
    };

    return (
        <div id="frame">
            <header>
                <p className='timer'>{formatDateTime(currentDateTime)}</p>
            </header>
            <canvas ref={canvasRef}></canvas>
            <footer>
                <a href="/versions" className='version'>{CURRENT_VERSION}</a>
                <div className='actions'>
                    <button className='btn-fast-forward' onClick={handleFastForward}>x{multiplier}</button>
                    <button className='btn-today' onClick={handleReset}>Today</button>
                </div>
            </footer>
        </div>
    );
};

export default Canvas;
