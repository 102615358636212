export const generateStarData = (canvas, starCount) => {
    const stars = [];
    for (let i = 0; i < starCount; i++) {
        const x = Math.random() * canvas.width;
        const y = Math.random() * canvas.height * 0.4; // Concentrate stars in the upper part of the canvas
        const radius = Math.random() * 0.8;
        const opacity = Math.random() * 0.8 + 0.2;
        stars.push({ x, y, radius, opacity });
    }
    return stars;
};

export const drawStars = (ctx, canvas, starData) => {
    starData.forEach(star => {
        ctx.fillStyle = `rgba(255, 255, 255, ${star.opacity})`;
        ctx.beginPath();
        ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 2, false);
        ctx.fill();
    });
};

