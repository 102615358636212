import React from 'react';
import './versions.css';

const Versions = () => {
    return (
        <div id="versions">
            <a className='home' href='/'>Back to home</a>
            <h1 className='main-heading'>Version History & Feature Updates</h1>
            <div className='versions'>
                <div className='version-item'>
                    <h2 className='heading'>4.0 <span className='status'>(Upcoming)</span></h2>
                    <div className='description'>
                        <p className='sub-heading'>Exciting New Features:</p>
                        <ul className='added'>
                            <li><b>Snow:</b> Experience the magic of snowfall based on real-time sessions, bringing a touch of winter wonder to your screen.</li>
                            <li><b>Code Optimization:</b> We've refined the code to ensure a smoother, faster experience.</li>
                        </ul>
                    </div>
                </div>
                <div className='version-item'>
                    <h2 className='heading'>3.0 <span className='status'>(Upcoming)</span></h2>
                    <div className='description'>
                        <p className='sub-heading'>What to Expect:</p>
                        <ul className='added'>
                            <li><b>Rain:</b> Get ready to see raindrops dance on your canvas, perfectly synced with real-life weather sessions.</li>
                            <li><b>Code Optimization:</b> Enhanced performance through meticulous code refinement.</li>
                        </ul>
                    </div>
                </div>
                <div className='version-item'>
                    <h2 className='heading'>2.2 <span className='status'>(Current)</span></h2>
                    <p className="datetime">2024-08-18</p>
                    <div className='description'>
                        <p className='sub-heading'>Latest Enhancements:</p>
                        <ul className='added'>
                            <li><b>Dynamic Sun:</b> The sun now moves across the sky, reflecting real-world positioning with sunrise at 6:30 AM and sunset at 6:30 PM. The colors of the sun change dynamically throughout the day.</li>
                            <li><b>Atmospheric Changes:</b> Experience dynamic atmosphere adjustments based on the sun's position, complete with stars at night.</li>
                            <li><b>Stars:</b> Enjoy a serene night sky filled with static stars.</li>
                            <li><b>Mountains:</b> The mountain designs are now more intricate and optimized for better performance.</li>
                            <li><b>Trees:</b> Improved leaf colors and optimized tree rendering for a more realistic and visually appealing scene.</li>
                            <li><b>Fast Forward Timer:</b> Travel through time with the new fast-forward feature, allowing you to skip ahead and see the changes.</li>
                            <li><b>Today Button:</b> Quickly reset the scene to reflect the current real-world date and time.</li>
                            <li><b>Version Tag:</b> A version tag has been added to the bottom left corner, with access to older version art for a nostalgic journey.</li>
                            <li><b>Clock:</b> Displays the current time in the top right corner.</li>
                        </ul>
                    </div>
                    <a className='view' href="/" target='_blank'>View Version</a>
                </div>
                <div className='version-item'>
                    <h2 className='heading'>1.0</h2>
                    <p className="datetime">2024-06-02</p>
                    <div className='description'>
                        <p className='description'>Foundations:</p>
                        <ul className='added'>
                            <li><b>Design Inspiration:</b> The initial design was inspired by a photo frame, bringing a personal touch to the digital canvas.</li>
                            <li><b>Mountains & Trees:</b> Every reload brings a new dynamic design for mountains and a fresh arrangement of trees, ensuring a unique view each time.</li>
                            <li><b>Sun:</b> The sun held a fixed position in the top right, marking the start of our journey.</li>
                            <li><b>Static Atmosphere:</b> The colors of the atmosphere were fixed, providing a stable and consistent backdrop.</li>
                        </ul>
                    </div>
                    <a className='view' href="https://v1.pixiepines.art/" target='_blank' rel='noreferrer'>View Version</a>
                </div>
            </div>
        </div>
    );
};

export default Versions;
