export const generateMountainData = (canvas) => {
    return [
        {
            baseHeight: 0.65,
            color: '#afa27f', // afa27f
            controlPoints: generateControlPoints(canvas, 0.65, 0.12 * canvas.height, 0.08 * canvas.height, 7),
        },
        {
            baseHeight: 0.55,
            color: '#8c917d', // 8c917d
            controlPoints: generateControlPoints(canvas, 0.55, 0.15 * canvas.height, 0.1 * canvas.height, 8),
        },
        {
            baseHeight: 0.45,
            color: '#5e5b54', // 5e5b54
            controlPoints: generateControlPoints(canvas, 0.45, 0.18 * canvas.height, 0.12 * canvas.height, 7),
        },
        {
            baseHeight: 0.35,
            color: '#292d30', // 292d30
            controlPoints: generateControlPoints(canvas, 0.35, 0.2 * canvas.height, 0.15 * canvas.height, 7),
        },
        {
            baseHeight: 0.25,
            color: '#191a14', // 191a14
            controlPoints: generateControlPoints(canvas, 0.25, 0.07 * canvas.height, 0 * canvas.height, 300),
        },
        {
            baseHeight: 0.16,
            color: '#1E201E', // 1b1c14
            controlPoints: generateControlPoints(canvas, 0.16, 0.09 * canvas.height, 0.08 * canvas.height, 3),
        },
    ];
    ;

};

const generateControlPoints = (canvas, baseHeight, peakHeight, valleyDepth, peakCount) => {
    const startX = 0;
    const startY = canvas.height * baseHeight;
    const segmentWidth = canvas.width / peakCount;

    const controlPoints = [];

    let prevX = startX;
    let prevY = startY;

    for (let i = 1; i <= peakCount; i++) {
        const cp1X = prevX + segmentWidth * 0.25;
        const cp1Y = prevY + (Math.random() * peakHeight - peakHeight / 2);

        const cp2X = prevX + segmentWidth * 0.75;
        const cp2Y = startY + (Math.random() * valleyDepth - valleyDepth / 2);

        const endX = prevX + segmentWidth;
        const endY = startY + (Math.random() * peakHeight - peakHeight / 2);

        controlPoints.push({ cp1X, cp1Y, cp2X, cp2Y, endX, endY });

        prevX = endX;
        prevY = endY;
    }

    return controlPoints;
};


export const drawMountains = (ctx, canvas, mountainData) => {
    mountainData.forEach(mountain => {
        drawMountain(ctx, canvas, mountain);
    });
};

const drawMountain = (ctx, canvas, mountain) => {
    const { baseHeight, color, controlPoints } = mountain;

    // Set the mountain color
    ctx.fillStyle = color;

    // Begin the path for the mountain
    ctx.beginPath();

    // Start the line from the bottom-left corner with an offset
    ctx.moveTo(0, 0);

    // Draw the first line up to the base height of the mountain
    const startY = canvas.height * baseHeight;
    ctx.lineTo(0, startY);

    // Use the pre-generated control points to draw the mountain
    controlPoints.forEach(point => {
        ctx.bezierCurveTo(point.cp1X, point.cp1Y, point.cp2X, point.cp2Y, point.endX, point.endY);
    });

    // Draw a straight line to the bottom-right corner
    ctx.lineTo(canvas.width, 0);

    // Close the path by drawing a line back to the start
    ctx.lineTo(0, 0);
    ctx.closePath();

    // Fill the mountain shape
    ctx.fill();
};
