const SUN_RADIUS = 60;

export const drawSun = (ctx, canvas, currentDateTime) => {
    const hours = currentDateTime.getHours();
    const minutes = currentDateTime.getMinutes();

    const totalMinutesInDay = (18 * 60 + 30) - (6 * 60 + 30); // Total minutes from 6:30 AM to 6:30 PM
    const minutesSinceSunrise = (hours * 60 + minutes) - (6 * 60 + 30);

    if (minutesSinceSunrise >= -60 && minutesSinceSunrise <= totalMinutesInDay + 60) {
        // Calculate the sun's horizontal position based on the time
        const positionRatio = minutesSinceSunrise / totalMinutesInDay;
        const sunX = positionRatio * canvas.width;

        // Adjust the sun's vertical position to start and end at the upper mid of the canvas
        const maxSunHeight = canvas.height * 0.95; // Adjust this to control the peak height of the sun
        const minSunHeight = canvas.height * 0.8; // Adjust this to control the starting and ending height
        const sunY = (-4 * (maxSunHeight - minSunHeight) * (positionRatio - 0.5) ** 2) + maxSunHeight;

        // Draw the sun
        ctx.fillStyle = '#f4845f'; // cf8150
        ctx.beginPath();
        ctx.arc(sunX, sunY, SUN_RADIUS, 0, Math.PI * 2, false); // Sun with a radius of 40
        ctx.fill();
    }
};

export const drawSunlightEffect = (ctx, sunX, sunY, canvas) => {
    const gradient = ctx.createRadialGradient(sunX, sunY, 0, sunX, sunY, SUN_RADIUS * 12);

    // Add color stops for a more natural sunlight effect
    gradient.addColorStop(0, 'rgba(255, 245, 200, 0.5)'); // Brighter at the center
    gradient.addColorStop(0.4, 'rgba(255, 223, 186, 0.3)'); // Warm light closer to the sun
    gradient.addColorStop(0.7, 'rgba(255, 207, 160, 0.15)'); // Softer light further away
    gradient.addColorStop(1, 'rgba(255, 190, 120, 0)'); // Fading out to transparent

    // Apply the gradient as the fill style
    ctx.fillStyle = gradient;

    // Fill the entire canvas to apply the sunlight effect
    ctx.fillRect(0, 0, canvas.width, canvas.height);
};

export const calculateSunPosition = (time, canvas) => {
    const hours = time.getHours();
    const minutes = time.getMinutes();

    const totalMinutesInDay = (18 * 60 + 30) - (6 * 60 + 30);
    const minutesSinceSunrise = (hours * 60 + minutes) - (6 * 60 + 30);

    let sunX = -100;
    let sunY = canvas.height * 0.2;

    if (minutesSinceSunrise >= -60 && minutesSinceSunrise <= totalMinutesInDay + 60) {
        const positionRatio = minutesSinceSunrise / totalMinutesInDay;
        sunX = positionRatio * canvas.width;

        const maxSunHeight = canvas.height * 0.95;
        const minSunHeight = canvas.height * 0.8;
        sunY = (-4 * (maxSunHeight - minSunHeight) * (positionRatio - 0.5) ** 2) + maxSunHeight;
    }

    return { sunX, sunY };
};

export const getDaylightColor = (sunX, sunY, canvas) => {
    const dawnColor = `rgba(255, 223, 186, 1)`; // Soft Peach, representing early dawn
    const middayColor = `rgba(255, 255, 240, 1)`; // Light Ivory, representing midday sunlight
    const afternoonColor = `rgba(255, 239, 186, 1)`; // Warm Cream, representing afternoon light
    const duskColor = `rgba(255, 183, 147, 1)`; // Soft Apricot, representing early dusk
    const nightColor = `rgba(5, 5, 20, 1)`; // Shade of black, resembling the night sky

    const fadeMargin = 50; // Margin before and after the canvas for smooth transition

    if (sunX < -1 * fadeMargin || sunX > canvas.width + fadeMargin) {
        return nightColor;
    }

    // Normalize sun position, adjusting for when sunX is outside the canvas
    const sunPositionNormalized = (sunX + fadeMargin) / (canvas.width + 2 * fadeMargin);

    // Smooth transition between colors
    if (sunPositionNormalized < 0.10) {
        // Transition from Night to Dawn
        return smoothTransition(nightColor, dawnColor, sunPositionNormalized / 0.10);
    } else if (sunPositionNormalized < 0.35) {
        // Dawn to Midday
        return smoothTransition(dawnColor, middayColor, (sunPositionNormalized - 0.10) / 0.25);
    } else if (sunPositionNormalized < 0.60) {
        // Midday to Afternoon
        return smoothTransition(middayColor, afternoonColor, (sunPositionNormalized - 0.35) / 0.25);
    } else if (sunPositionNormalized < 0.85) {
        // Afternoon to Dusk
        return smoothTransition(afternoonColor, duskColor, (sunPositionNormalized - 0.60) / 0.25);
    } else if (sunPositionNormalized <= 1.0) {
        // Dusk to Night
        return smoothTransition(duskColor, nightColor, (sunPositionNormalized - 0.85) / 0.15);
    } else {
        // Outside canvas after dusk, transition fully to night
        return nightColor;
    }
};

// Helper function to smoothly transition between two colors
const smoothTransition = (color1, color2, factor) => {
    factor = factor * factor * (3 - 2 * factor); // Smoothstep function for smoother transition

    const c1 = color1.match(/\d+/g).map(Number);
    const c2 = color2.match(/\d+/g).map(Number);

    const result = c1.map((val, index) => Math.round(val + factor * (c2[index] - val)));
    return `rgba(${result[0]}, ${result[1]}, ${result[2]}, 1)`;
};

