export const calculateTreePositions = (canvas) => {
    const treeCount = parseInt(canvas.width / 80);
    const maxAttempts = 3;
    const trees = []; // Array to store all tree data
    const minSpacing = 70; // Dynamic minimum spacing between trees

    for (let i = 0; i < treeCount; i++) {
        let treePlaced = false;
        let attempts = 0;
        let baseX, baseY, trunkHeight, trunkWidth, leafSize, heightMultiplier;

        while (!treePlaced && attempts < maxAttempts) {
            // Calculate initial X position
            if (i === 0) {
                baseX = Math.random() * minSpacing; // Random position for the first tree within the first segment
            } else {
                const prevTree = trees[trees.length - 1]; // Get the last successfully placed tree
                baseX = prevTree.baseX + minSpacing + Math.random() * minSpacing;
            }

            // Ensure the tree stays within the canvas boundaries
            if (baseX + minSpacing > canvas.width) {
                baseX = Math.random() * (canvas.width - minSpacing); // Recalculate to fit within the canvas
            }

            // Y position is random within the specified range
            baseY = Math.random() * (canvas.height * 0.13);

            // Adjust tree sizes
            trunkHeight = 80; // Fixed trunk height for simplicity
            trunkWidth = 12;   // Fixed trunk width
            leafSize = 50 + parseInt(Math.random() * 10);     // Fixed leaf size
            heightMultiplier = Math.random() * 0.5 + 0.75; // Height multiplier for variation

            const newTree = { baseX, baseY, trunkHeight, trunkWidth, leafSize, heightMultiplier };

            // Ensure the new tree does not overlap with the previous one
            if (i === 0 || baseX - trees[trees.length - 1].baseX > trunkWidth) {
                trees.push(newTree);
                treePlaced = true;
            } else {
                // Recalculate baseX and baseY for a reattempt
                baseX = Math.random() * (canvas.width - minSpacing);
                baseY = Math.random() * (canvas.height * 0.14);
            }

            attempts++;
        }

        if (!treePlaced) {
            console.log(`Skipped tree ${i + 1} after ${attempts} attempts due to overlapping issues.`);
        }
    }

    return trees;
};

export const drawTrees = (ctx, trees) => {
    if (!Array.isArray(trees)) {
        console.error("drawTrees expects an array, but got:", trees);
        return;
    }

    trees.forEach(tree => {
        drawTree(ctx, tree.baseX, tree.baseY, {
            trunkHeight: tree.trunkHeight,
            trunkWidth: tree.trunkWidth,
            leafSize: tree.leafSize,
            heightMultiplier: tree.heightMultiplier,
        });
    });
};

const drawTree = (ctx, baseX, baseY, options = {}) => {
    const {
        trunkHeight = 50,
        trunkWidth = 10,
        leafSize = 40,
        heightMultiplier = 1,
        trunkColor = '#8B4513',
        shadowColor = 'rgba(0, 0, 0, 0.5)',
        shadowOffsetX = 2,
        shadowOffsetY = -6,
    } = options;

    const adjustedTrunkHeight = trunkHeight * heightMultiplier;
    const adjustedLeafSize = leafSize * heightMultiplier;

    // Set shadow properties
    ctx.shadowColor = shadowColor;
    ctx.shadowBlur = 10;
    ctx.shadowOffsetX = shadowOffsetX;
    ctx.shadowOffsetY = shadowOffsetY;

    // Draw trunk
    ctx.fillStyle = trunkColor;
    ctx.fillRect(baseX - trunkWidth / 2, baseY, trunkWidth, adjustedTrunkHeight);

    // Draw leaves
    ctx.fillStyle = '#166138';
    ctx.beginPath();
    ctx.moveTo(baseX, baseY + adjustedTrunkHeight);
    ctx.lineTo(baseX - adjustedLeafSize, baseY + adjustedTrunkHeight - adjustedLeafSize);
    ctx.lineTo(baseX + adjustedLeafSize, baseY + adjustedTrunkHeight - adjustedLeafSize);
    ctx.closePath();
    ctx.fill();

    ctx.beginPath();
    ctx.moveTo(baseX, baseY + adjustedTrunkHeight + adjustedLeafSize / 2);
    ctx.lineTo(baseX - adjustedLeafSize * 0.75, baseY + adjustedTrunkHeight - adjustedLeafSize / 2);
    ctx.lineTo(baseX + adjustedLeafSize * 0.75, baseY + adjustedTrunkHeight - adjustedLeafSize / 2);
    ctx.closePath();
    ctx.fill();

    ctx.beginPath();
    ctx.moveTo(baseX, baseY + adjustedTrunkHeight + adjustedLeafSize);
    ctx.lineTo(baseX - adjustedLeafSize * 0.5, baseY + adjustedTrunkHeight);
    ctx.lineTo(baseX + adjustedLeafSize * 0.5, baseY + adjustedTrunkHeight);
    ctx.closePath();
    ctx.fill();

    // Reset shadow settings after drawing the tree
    ctx.shadowColor = 'transparent';
};
